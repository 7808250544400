<template>
  <div class="mt-2">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100">
        <img
            fluid
            :src="require(isDark ? '@/assets/images/logo/promostore-white.svg' : '@/assets/images/logo/promostore.svg') "
            class="max-w-img-190 d-block m-auto"
            alt="Not authorized page"
        />
        <h1 class="mb-1 mt-3">
          Aviso de privacidad integral
        </h1>

        <p><strong>&nbsp;</strong></p>
        <p><strong>&Uacute;ltima modificaci&oacute;n</strong>: 28 de febrero del 2022.</p>
        <p>&nbsp;</p>
        <p>Productos y Servicios que Dignifican, S. de R.L. (en lo sucesivo, el <strong>&ldquo;Responsable&rdquo;</strong>), en cumplimiento de la Ley Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los Particulares (indistintamente referida en adelante como la <strong>&ldquo;Ley&rdquo; </strong>o la <strong>&ldquo;Ley de Datos Personales&rdquo;</strong>), pone a disposici&oacute;n el presente Aviso de Privacidad Integral (referido en adelante como el <strong>&ldquo;Aviso de Privacidad&rdquo;</strong>) con el objeto de informar a nuestros visitantes, usuarios, clientes, y/o cualquier persona que haga uso de los servicios y/o adquiera los productos del Responsable (referido en adelante indistintamente como <strong>&ldquo;Usted&rdquo; </strong>o el <strong>&ldquo;Titular&rdquo; </strong>de los Datos Personales), los alcances y condiciones generales del tratamiento de la informaci&oacute;n personal que obtenemos del Titular (para efectos del presente Aviso de Privacidad, referida como los <strong>&ldquo;Datos Personales&rdquo;</strong>), as&iacute; como informar al Titular a fin de que est&eacute;n en posibilidad de tomar decisiones informadas sobre el uso de sus Datos Personales, as&iacute; como de mantener el control y disposici&oacute;n sobre ellos.</p>
        <h3>I.&nbsp; Disposiciones Generales.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El presente Aviso de Privacidad ser&aacute; aplicable a los servicios prestados por el Responsable (en adelante, los <strong>&ldquo;Servicios&rdquo;</strong>) a trav&eacute;s de la plataforma web accesible mediante la siguiente direcci&oacute;n URL: <a href="https://promostore.clarios.com/">https://promostore.clarios.com </a>(en adelante, la <strong>&ldquo;P&aacute;gina Web&rdquo; </strong>del Responsable), o cualquier otra que en un futuro la sustituya, as&iacute; como respecto de las aplicaciones m&oacute;viles, aplicaciones de escritorio, otras p&aacute;ginas web, plataformas, perfiles en redes sociales y dem&aacute;s medios o canales de comunicaciones que pertenezcan, o sean controlados por el Responsable, y que &eacute;ste identifique o refiera suyos, siendo aplicable igualmente a todo el contenido, informaci&oacute;n y/o materiales que el Responsable produzca, publique o ponga a disposici&oacute;n del Titular o del p&uacute;blico en general.</p>
        <p>A reserva de que expresamente se se&ntilde;ale algo distinto, los t&eacute;rminos inicializados con may&uacute;scula, ya sean utilizados en masculino o femenino, singular o plural, seg&uacute;n sea el caso, tendr&aacute;n el significado que a los mismos se les atribuye conforme a la Ley de Datos Personales, el Reglamento de la Ley de Datos Personales, los Lineamientos del Aviso de Privacidad emitidos por la Secretar&iacute;a de Econom&iacute;a de M&eacute;xico, o el que se les atribuye en el presente Aviso de Privacidad una vez que dichos t&eacute;rminos sean referidos por primera vez en negritas y entre comillas. De igual manera, y sin perjuicio de lo anterior, ser&aacute;n aplicables los t&eacute;rminos definidos de los T&eacute;rminos y Condiciones del Responsable (en adelante, los <strong>&ldquo;T&eacute;rminos y Condiciones&rdquo;</strong>), por lo que el contenido de este Aviso de Privacidad deber&aacute; interpretarse de manera arm&oacute;nica con lo establecido en los T&eacute;rminos y Condiciones, los cuales pueden ser consultados a trav&eacute;s de la siguiente direcci&oacute;n URL: <a href="https://promostore.clarios.com/">https://promostore.clarios.com</a></p>
        <p>&nbsp;</p>
        <h3>II.&nbsp; Identificaci&oacute;n del Responsable del tratamiento de Datos Personales.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>De conformidad con lo establecido en la Ley de Datos Personales, se informa a continuaci&oacute;n la identidad, domicilio e informaci&oacute;n de contacto del Responsable, quien igualmente cumplir&aacute; con la funci&oacute;n de dar tr&aacute;mite a las solicitudes que el Titular efect&uacute;e de conformidad con el presente Aviso de Privacidad, incluyendo las solicitudes de ejercicio de los Derechos ARCO del Titular.</p>
        <p><strong>Denominaci&oacute;n social: </strong>Productos y Servicios que Dignifican, S. de R.L.</p>
        <p>&nbsp;</p>
        <p><strong>&Aacute;rea responsable: </strong>Servicio a Cliente. <strong>Correo electr&oacute;nico: </strong><a href="mailto:ayuda@promostore.com">ayuda@promostore.com</a> <strong>Tel&eacute;fono: </strong>2294375437</p>
        <p><strong>Domicilio: </strong>Isabel La Cat&oacute;lica 462, Piso 1B, Fracc. Reforma, C.P. 91919, Veracruz, Veracruz, M&eacute;xico.</p>
        <p>&nbsp;</p>
        <h3>III.&nbsp; Datos Personales que se recaban.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Todos los Datos Personales recabados ser&aacute;n entregados directamente por el Titular de manera voluntaria, consentida e informada. En ning&uacute;n momento el Responsable obligar&aacute; al Titular a entregar Dato Personal alguno que el Titular se niegue a proveer de manera voluntaria. No obstante lo anterior, el Titular reconoce que la no entrega de ciertos Datos Personales de los que se consignan en el presente Aviso de Privacidad, podr&iacute;a resultar, ya sea total o parcialmente, en la imposibilidad material o legal para prestar los Servicios.</p>
        <p>Ninguna entrega de Datos Personales que el Titular efect&uacute;e se interpretar&aacute; como una transmisi&oacute;n de propiedad sobre dichos datos. Considerando lo anterior, y de conformidad con la Ley, el Titular tendr&aacute; en todo momento la posibilidad de ejercer cualquiera de sus derechos contemplados en la Ley de Datos Personales, incluyendo el derecho al acceso, rectificaci&oacute;n, cancelaci&oacute;n, u oposici&oacute;n respecto al uso de sus Datos Personales (referidos para efecto del presente Aviso de Privacidad conjuntamente como los <strong>&ldquo;Derechos ARCO&rdquo;</strong>).</p>
        <p>El Responsable le informa al Titular que en ning&uacute;n momento recabar&aacute; Datos Personales Sensibles (como se definen por Ley), y que, en caso de tener que hacerlo, el Responsable recabar&aacute; en t&eacute;rminos de Ley el consentimiento expreso e informado por parte del Titular, y dar&aacute; tratamiento a los mismos conforme a las disposiciones aplicables al tratamiento de dichos Datos Personales Sensibles, tal y como se establecen en la Ley de Datos Personales y dem&aacute;s Legislaci&oacute;n Aplicable.</p>
        <p>Los Datos Personales del Titular que el Responsable podr&aacute; recabar del Titular son los que a continuaci&oacute;n se detallan:</p>
        <p>&nbsp;</p>
        <ol>
          <li><u>Informaci&oacute;n del Titular:</u> <strong>(i) </strong>Datos de identificaci&oacute;n (nombre completo, edad, sexo, as&iacute; como la dem&aacute;s informaci&oacute;n que identifique al Titular como empleado de la Empresa); <strong>(ii) </strong>datos de contacto (domicilio, tel&eacute;fono y correo electr&oacute;nico); <strong>(iii) </strong>en caso de requerirlo, informaci&oacute;n fiscal (nombre del contribuyente, clave de alta en el Registro Federal de Contribuyentes y domicilio fiscal); <strong>(iv) </strong>la dem&aacute;s informaci&oacute;n y/o documentaci&oacute;n del Titular que el Responsable requiera recabar con el prop&oacute;sito de cumplir obligaciones derivadas de la relaci&oacute;n jur&iacute;dica entre el Titular y el Responsable derivada de la prestaci&oacute;n de los Servicios y/o aquella que el Responsable est&eacute; legalmente obligado a recabar en t&eacute;rminos de las leyes, reglamentos, disposiciones, y/o cualquier normatividad que se encuentre vigente y sea aplicable a la prestaci&oacute;n de los Servicios (la <strong>&ldquo;Legislaci&oacute;n Aplicable&rdquo;</strong>); <strong>(v) </strong>la dem&aacute;s informaci&oacute;n que el Titular decida proporcionarnos y que sea esencial para la prestaci&oacute;n de los Servicios.</li>
          <li><u>Informaci&oacute;n de Terceros:</u> Para efecto de la prestaci&oacute;n de Servicios es posible que requiramos recabar, a trav&eacute;s del Titular, los mismos Datos Personales referidos en el inciso anterior, respecto de Terceros. Respecto de la Informaci&oacute;n de Terceros que recabemos, el Titular deber&aacute; contar en todo momento con la autorizaci&oacute;n expresa del Tercero para proveernos sus Datos Personales. En caso de contravenci&oacute;n a lo anterior, el Titular responder&aacute; por los da&ntilde;os y perjuicios que ocasione por tal motivo al Responsable.</li>
          <li><em><u>Cookies, web beacons </u></em><u>y tecnolog&iacute;as similares:</u> Le informamos que en nuestra P&aacute;gina Web utilizamos <em>cookies</em>, <em>web beacons </em>y otras tecnolog&iacute;as o mecanismos similares a trav&eacute;s de los cuales es posible recabar Datos Personales de manera autom&aacute;tica y simult&aacute;nea al tiempo en que el Titular hace contacto con dichos Las tecnolog&iacute;as referidas en este inciso son utilizadas con la finalidad de personalizar el contenido, anuncios y funciones de nuestra P&aacute;gina Web y con ello brindarle un mejor servicio y experiencia de usuario al navegar en nuestra P&aacute;gina Web, as&iacute; como para an&aacute;lisis de tr&aacute;fico web.</li>
        </ol>
        <p>Las <em>cookies </em>son archivos de datos que se almacenan en el disco duro del equipo de c&oacute;mputo o del dispositivo de comunicaciones electr&oacute;nicas de un usuario al navegar en un sitio de internet espec&iacute;fico, el cual permite intercambiar informaci&oacute;n de estado entre dicho sitio y el navegador del usuario. La informaci&oacute;n de estado puede revelar medios de identificaci&oacute;n de sesi&oacute;n, autenticaci&oacute;n o preferencias del usuario, as&iacute; como cualquier dato almacenado por el navegador respecto al sitio de internet. Por su parte, las <em>web beacons </em>son im&aacute;genes visibles u ocultas insertada dentro de un sitio web o correo electr&oacute;nico, que se utilizan para monitorear el comportamiento del usuario en estos medios. A trav&eacute;s de &eacute;stos se puede obtener informaci&oacute;n como la direcci&oacute;n IP de origen, navegador utilizado, sistema operativo, momento en que se accedi&oacute; a la p&aacute;gina web, y en el caso del correo electr&oacute;nico, la asociaci&oacute;n de los datos anteriores con el destinatario.</p>
        <p>La habilitaci&oacute;n del uso de <em>cookies, web beacons </em>y tecnolog&iacute;as similares es en su mayor&iacute;a posible a trav&eacute;s del ajuste de configuraci&oacute;n de privacidad y seguridad correspondiente al navegador de internet que utilice</p>
        <p>&nbsp;</p>
        <p>para acceder a nuestra P&aacute;gina Web. No obstante lo anterior, la desactivaci&oacute;n de dichas tecnolog&iacute;as podr&iacute;a afectar la experiencia de navegaci&oacute;n en nuestra P&aacute;gina Web. A continuaci&oacute;n se enlistan los enlaces para acceder a informaci&oacute;n concreta sobre c&oacute;mo deshabilitar el uso de <em>cookies </em>en los navegadores de internet m&aacute;s comunes: <a href="https://support.google.com/chrome/answer/95647?hl=es">Google Chrome</a><u>; </u><a href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a><u>; </u><a href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#%3A~%3Atext%3DAbre%20Microsoft%20Edge%20y%2C%20a%2Cy%20otros%20datos%20del%20sitio">Microsoft Edge</a><u>; </u><a href="https://support.mozilla.org/es/kb/proteccion-de-rastreo-mejorada-en-firefox-para-esc">Mozilla Firefox</a><u>; </u><a href="https://support.apple.com/es-es/HT201265">Safari</a><u>.</u></p>
        <p>Para efecto de recibir asesor&iacute;a sobre c&oacute;mo deshabilitar el uso de <em>cookies, web beacons </em>y tecnolog&iacute;as similares en su navegador, le pedimos ponerse en contacto con nosotros a trav&eacute;s del correo electr&oacute;nico se&ntilde;alado en el presente Aviso de Privacidad para recibir solicitudes relacionadas con el ejercicio de Derechos ARCO (ayuda@promostore.com).</p>
        <h3>IV.&nbsp; Finalidades del tratamiento y uso de Datos Personales.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El tratamiento de los Datos Personales del Titular comprender&aacute; las siguientes finalidades primarias que dan origen a la relaci&oacute;n jur&iacute;dica entre el Responsable y el Titular, y que son necesarias para cumplir con las obligaciones derivadas de la prestaci&oacute;n de los Servicios (referidas, para efecto del presente Aviso de Privacidad, como las <strong>&ldquo;Finalidades Primarias&rdquo;</strong>):</p>
        <ul>
          <li>La prestaci&oacute;n de los Servicios, de conformidad con los T&eacute;rminos y</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>El registro del Titular y la creaci&oacute;n de la Cuenta del</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>Contactar al Titular para atender y dar seguimiento a las solicitudes, comentarios, quejas, sugerencias y/o solicitudes de soporte t&eacute;cnico que se generen, as&iacute; como para comunicar los cambios o mejoras efectuados a los</li>
          <li>Uso interno de los datos para fines estad&iacute;sticos y de an&aacute;lisis para agilizar, adaptar, actualizar y, en general, modificar los Servicios a fin de proporcionar mejores y m&aacute;s completos Servicios.</li>
          <li>Las dem&aacute;s finalidades que sean compatibles o an&aacute;logas con las</li>
        </ul>
        <p>&nbsp;</p>
        <p>De igual manera, los Datos Personales del Titular, salvo que el Titular notifique de manera expresa al Responsable lo contrario, podr&aacute;n ser utilizados para las siguientes finalidades secundarias que no dan origen a la relaci&oacute;n jur&iacute;dica entre el Responsable y el Titular, y que no son necesarias para cumplir con las obligaciones derivadas de la prestaci&oacute;n de los Servicios (referidas, para efecto del presente Aviso de Privacidad, como las <strong>&ldquo;Finalidades Secundarias&rdquo; </strong>y en conjunto con las Finalidades Primarias como las <strong>&ldquo;Finalidades&rdquo;</strong>):</p>
        <ul>
          <li>Contactar al Titular y enviarle informaci&oacute;n relativa a los productos o servicios del Responsable, promociones disponibles as&iacute; como otras comunicaciones con contenido relacionado con los Servicios que consideramos pudiera ser de su inter&eacute;s.</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <li>An&aacute;lisis y estudio de datos con fines estad&iacute;sticos, acad&eacute;micos, de investigaci&oacute;n, divulgaci&oacute;n cient&iacute;fica, estudio de mercado, fines publicitarios, mercadot&eacute;cnicos o de prospecci&oacute;n comercial y/o dem&aacute;s similares o an&aacute;logos, con o sin lucro de por</li>
          <li>Las dem&aacute;s finalidades que sean compatibles o an&aacute;logas con las</li>
        </ul>
        <p>&nbsp;</p>
        <p>El Titular podr&aacute; en cualquier momento negar o revocar su consentimiento as&iacute; como oponerse al tratamiento de sus Datos Personales para las Finalidades Secundarias mediante notificaci&oacute;n enviada al Responsable, cumpliendo con los requisitos establecidos en el presente Aviso de Privacidad respecto de la Solicitud de ejercicio de Derechos ARCO.</p>
        <h3>V.&nbsp; Transferencia de Datos Personales.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El Responsable manifiesta, y el Titular reconoce y manifiesta su consentimiento respecto que los Datos Personales podr&aacute;n ser transferidos a Terceros, dentro o fuera del territorio de los Estados Unidos Mexicanos, con motivo de la prestaci&oacute;n de los Servicios.</p>
        <p>Los Terceros a quienes el Responsable podr&aacute; transferir los Datos Personales incluyen a las Paqueter&iacute;as y otros prestadores de servicios relacionados con el env&iacute;o y entrega de los Productos a adquirir en la P&aacute;gina Web. Las transferencias de Datos Personales a efectuar se justifican bajo la finalidad expresa de prestar los Servicios que se ofrecen a trav&eacute;s de la P&aacute;gina Web.</p>
        <p>Salvo que se trate de alguno de los casos de excepci&oacute;n al consentimiento establecidos en la Ley de Datos Personales, cuando el Titular se niegue, desee revocar su consentimiento u oponerse a que el Responsable efectu&eacute; transferencias respecto de sus Datos Personales, deber&aacute; manifestar dicha negativa a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico se&ntilde;alada por el Responsable para efecto de recibir Solicitudes de Ejercicio de Derechos ARCO (ayuda@promostore.com).</p>
        <p>El Responsable se compromete a que har&aacute; del conocimiento y mantendr&aacute; el presente Aviso de Privacidad a disposici&oacute;n y accesible de los Terceros a los que, en su caso, transfiera Datos Personales, inform&aacute;ndole a estos las Finalidades a las que el Titular haya sujetado el uso y tratamiento de sus Datos Personales.</p>
        <p>No obstante lo anterior, Responsable no ser&aacute; responsable por el tratamiento o uso indebido en que incurran los Terceros a los que se otorgue acceso a Datos Personales como parte de los Servicios, m&aacute;s all&aacute; de la expectativa razonable de privacidad, siempre y cuando el Responsable cumpla con llevar a cabo medidas razonables para proteger los Datos Personales del Titular y/o de cualquier otro Tercero cuyos Datos Personales hubieran sido prove&iacute;dos por el Titular, y salvo los casos en que medie dolo, mala fe, o negligencia grave por parte del Responsable.</p>
        <h3>VI.&nbsp; Consentimiento al uso y tratamiento de los Datos Personales.</h3>
        <p>&nbsp;</p>
        <p>Al momento de solicitar y/o hacer uso de cualquiera de los Servicios, y previo a la entrega de cualquier Dato Personal al Responsable, el Titular acepta y reconoce que ha le&iacute;do y comprende en su totalidad el contenido del presente Aviso de Privacidad. Con base en lo anterior, el Titular reconoce y acepta que al momento de solicitar y/o hacer uso de cualquiera de los Servicios, y previo a la entrega de cualquier Dato Personal al Responsable, manifiesta su consentimiento al tratamiento y uso de sus Datos Personales para las Finalidades establecidas en el mismo, y de conformidad con los t&eacute;rminos contenidos en el presente Aviso de Privacidad.</p>
        <p>No obstante lo anterior, en caso de que el Titular niegue su consentimiento, desee revocar su consentimiento u oponerse a que el Responsable trate sus Datos Personales respecto de alguna de las Finalidades Secundarias establecidas en el presente Aviso de Privacidad, deber&aacute; manifestar su voluntad a trav&eacute;s de la direcci&oacute;n de correo electr&oacute;nico se&ntilde;alada por el Responsable para efecto de recibir Solicitudes de Ejercicio de Derechos ARCO (ayuda@promostore.com)</p>
        <p>En caso de que el Titular est&eacute; en desacuerdo con el uso de sus Datos Personales para alguna de las Finalidades Primarias que son necesarias y dan origen a la relaci&oacute;n jur&iacute;dica entre el Responsable y el Titular, de conformidad con lo establecido en el Aviso de Privacidad, el Titular deber&aacute; abstenerse de solicitar y/o contratar cualquiera de los Servicios, as&iacute; como de proporcionar al Responsable cualquier Dato Personal, o bien, deber&aacute;, previo a la entrega de cualquier Dato Personal, consultar con el Responsable sobre la posibilidad de contratar los Servicios bajo las condiciones propuestas por el Titular. Lo anterior sin perjuicio del derecho que el Responsable se reserva a negarle al Titular la prestaci&oacute;n de los Servicios cuando se considere que existe una imposibilidad justificada.</p>
        <p>Sin perjuicio de lo anterior, el Responsable se reserva el derecho a solicitar en adelante, a trav&eacute;s de los medios que considere convenientes, verificaciones adicionales de manifestaci&oacute;n del consentimiento por parte del Titular al tratamiento y uso de sus Datos Personales.</p>
        <p>Al momento de ingresar, visitar, navegar, hacer uso y/o registrarse en la P&aacute;gina Web, as&iacute; como al momento de solicitar, contratar, comprar, adquirir y/o de cualquier otra forma beneficiarse de los productos o servicios del Responsable, el Titular reconoce y acepta que ha le&iacute;do y comprendido a cabalidad los t&eacute;rminos establecidos en el presente Aviso de Privacidad, por lo cual el Titular otorga al Responsable el m&aacute;s amplio deslinde de responsabilidad que en derecho proceda por cualquier uso o tratamiento que se le d&eacute; a sus Datos Personales, siempre que dicho uso o tratamiento se ajuste a lo establecido en este Aviso de Privacidad, incluyendo sus posibles modificaciones futuras.</p>
        <h3>VII.&nbsp; Medidas de seguridad y protecci&oacute;n de los Datos Personales.</h3>
        <p>&nbsp;</p>
        <p>El Responsable mantiene medidas de seguridad administrativas, t&eacute;cnicas y f&iacute;sicas de seguridad a niveles razonables que permiten proteger los Datos Personales contra da&ntilde;o, p&eacute;rdida, alteraci&oacute;n, destrucci&oacute;n o uso, acceso o tratamiento no autorizado. Las medidas de seguridad adoptadas por el Responsable respecto de los Datos Personales ser&aacute;n en todo momento igual o mayores a las adoptadas para mantener el manejo de la informaci&oacute;n propia del Responsable. No obstante lo anterior, los Titulares deben estar conscientes de que las medidas de seguridad en internet no son inexpugnables y por lo mismo, deber&aacute;n adoptar por su propia cuenta las medidas y precauciones que estimen pertinentes para cuando opten por proveer sus Datos Personales al Responsable.</p>
        <p>El Responsable le informa que sus Datos Personales podr&aacute;n ser almacenados en bases de datos, as&iacute; como en servicios, aplicaciones e infraestructura de c&oacute;mputo en la nube (<em>cloud computing</em>), siendo lo anterior necesario y esencial para llevar a cabo las Finalidades descritas en el presente Aviso de Privacidad, y en general, para la prestaci&oacute;n de los Servicios. El Responsable se compromete a llevar a cabo las medidas necesarias y razonables para salvaguardar la integridad y protecci&oacute;n de la informaci&oacute;n almacenada en los canales y medios referidos en el presente p&aacute;rrafo.</p>
        <h3>VIII.&nbsp; Solicitud de Ejercicio de Derechos ARCO y/o solicitudes relacionadas con el ejercicio de otros derechos contemplados en la Ley de Datos Personales</h3>
        <p>Para efecto de ejercer cualquiera de los derechos establecidos en la Ley de Datos Personales, su Reglamento y/o el presente Aviso de Privacidad, el Titular deber&aacute; comunicar la solicitud correspondiente a trav&eacute;s de la siguiente direcci&oacute;n de correo electr&oacute;nico: <a href="mailto:ayuda@promostore.com"><strong>ayuda@promostore.com</strong></a></p>
        <p>El medio de comunicaci&oacute;n antes se&ntilde;alado, as&iacute; como el procedimiento y contenido de este apartado ser&aacute; aplicable respecto de: <strong>a) </strong>las solicitudes del Titular para efecto de ejercer cualquiera de sus Derechos ARCO;</p>
        <ol>
          <li>solicitud de informaci&oacute;n sobre c&oacute;mo desactivar el uso de <em>cookies</em>, <em>web beacons </em>y tecnolog&iacute;as similares;</li>
          <li>manifestar negativa u oposici&oacute;n, as&iacute; como, en su caso, revocar su consentimiento al tratamiento de Datos Personales para las Finalidades Secundarias establecidas en el presente Aviso de Privacidad; <strong>d) </strong>manifestar negativa u oposici&oacute;n, as&iacute; como, en su caso, revocar su consentimiento respecto de las transferencias de Datos Personales; <strong>e) </strong>solicitudes para limitar el uso y divulgaci&oacute;n de Datos Personales, distintos al ejercicio de Derechos</li>
        </ol>
        <p>Cada una de las solicitudes que el Titular presente en t&eacute;rminos del presente apartado (en adelante, cada una de ellas referida como la <strong>&ldquo;Solicitud&rdquo;</strong>), deber&aacute; acompa&ntilde;arse de, cuando menos, lo siguiente: <strong>(i) </strong>nombre completo del Titular, domicilio, tel&eacute;fono de contacto y/o correo electr&oacute;nico, el cual ser&aacute; utilizado para dar respuesta a la Solicitud; <strong>(ii) </strong>copia de la identificaci&oacute;n oficial del Titular, la cual podr&aacute; consistir en credencial para votar o pasaporte vigente; <strong>(iii) </strong>trat&aacute;ndose de que la Solicitud se presente mediante representante legal</p>
        <p>&nbsp;</p>
        <p>del Titular, se deber&aacute; incluir copia de la identificaci&oacute;n oficial del representante legal y del poder notarial del representante legal a favor de quien se haya conferido poder general para pleitos y cobranzas, de conformidad con la Legislaci&oacute;n Aplicable, o bien, en su caso, la carta poder firmada ante dos testigos, acompa&ntilde;ando igualmente con copia de la identificaci&oacute;n oficial de los testigos; <strong>(iv) </strong>la descripci&oacute;n clara y precisa de los Datos Personales respecto de los que se busca ejercer uno o m&aacute;s derechos; <strong>(v) </strong>la descripci&oacute;n clara y precisa del derecho que se busca ejercer a trav&eacute;s de la Solicitud, es decir, especificar a cu&aacute;l de los derechos mencionados en el p&aacute;rrafo anterior se refiere la Solicitud; <strong>(vi) </strong>trat&aacute;ndose de rectificaci&oacute;n de Datos Personales, deber&aacute; clarificar qu&eacute; modificaciones se aplicar&aacute;n, proporcionando documentos oficiales que soporten la Solicitud; <strong>(vii) </strong>trat&aacute;ndose del derecho de acceso a Datos Personales, la indicaci&oacute;n del medio de reproducci&oacute;n mediante el cual el Titular desee obtener la informaci&oacute;n o Datos Personales solicitados, pudiendo elegir entre la expedici&oacute;n de copias simples, a coste del interesado, o en formato de documento electr&oacute;nico sin coste; <strong>(viii) </strong>cualquier otro elemento o documento que facilite la localizaci&oacute;n de los Datos Personales objeto de la Solicitud; <strong>(ix) </strong>los dem&aacute;s documentos, informaci&oacute;n y/o requisitos aplicables de conformidad con la Legislaci&oacute;n Aplicable; y, <strong>(x) </strong>la dem&aacute;s informaci&oacute;n o documentaci&oacute;n que el Responsable justificadamente requiera para dar atenci&oacute;n a la Solicitud presentada por el Titular.</p>
        <p>El Responsable atender&aacute; en un plazo m&aacute;ximo de 20 (Veinte) d&iacute;as h&aacute;biles, contados desde la fecha en que se recibi&oacute; la Solicitud en los t&eacute;rminos del presente apartado, emitiendo una respuesta a trav&eacute;s del correo electr&oacute;nico del Titular, o al correo electr&oacute;nico que el Titular haya se&ntilde;alado en la solicitud. En el caso de resultar procedente la solicitud correspondiente, el Responsable dar&aacute; cumplimiento a la misma dentro de un plazo de 15 (Quince) d&iacute;as h&aacute;biles siguientes a la comunicaci&oacute;n de la resoluci&oacute;n antes referida. Los plazos referidos en el presente p&aacute;rrafo podr&aacute;n ser ampliados por &uacute;nica ocasi&oacute;n por un plazo igual, respecto de cada Solicitud en particular, siempre y cuando as&iacute; lo justifiquen las circunstancias del caso. Sin perjuicio de lo anterior, trat&aacute;ndose de Solicitudes de acceso a Datos Personales, el Titular, o su representante legal, en su caso, deber&aacute; acreditar su personalidad previo a la entrega de los Datos Personales.</p>
        <p>Si el Titular considera que su derecho a la protecci&oacute;n de sus Datos Personales ha sido lesionado por alguna conducta u omisi&oacute;n de nuestra parte, o presume alguna violaci&oacute;n a las disposiciones previstas en la Ley de Datos Personales, su Reglamento y dem&aacute;s ordenamientos aplicables, el Titular podr&aacute; iniciar el procedimiento de protecci&oacute;n de derechos ante el Instituto Nacional de Transparencia, Acceso a la Informaci&oacute;n y Protecci&oacute;n de Datos Personales (INAI) dentro de los 15 (Quince) d&iacute;as h&aacute;biles siguientes a la fecha en que reciba respuesta a su Solicitud, o bien, en caso de que el Responsable no haya dado respuesta a su Solicitud, a partir de que venza el plazo establecido para dar respuesta a las Solicitudes recibidas, de conformidad con el presente apartado. Para mayor informaci&oacute;n, le sugerimos visitar la p&aacute;gina oficial de internet del INAI: <a href="https://home.inai.org.mx/">https://home.inai.org.mx/.</a></p>
        <p>&nbsp;</p>
        <h3>IX.&nbsp; Modificaciones al Aviso de Privacidad.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de los siguientes casos: <strong>(i) </strong>surgimiento de nuevos requerimientos conforme a la Ley de Datos Personales y/o la Legislaci&oacute;n Aplicable; <strong>(ii) </strong>por as&iacute; convenir a los intereses del Responsable, en relaci&oacute;n con los Servicios;</p>
        <p><strong>(iii) </strong>por cambios efectuados a nuestras pr&aacute;cticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas an&aacute;logas. Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de Privacidad, a trav&eacute;s de la siguiente direcci&oacute;n URL: <a href="https://promostore.clarios.com/">https://promostore.clarios.com</a></p>
        <p>El Responsable manifiesta, y el Titular acepta que, dependiendo de las modificaciones que se efect&uacute;en al presente Aviso de Privacidad, es probable que se le solicite al Titular, mediante medidas adicionales de verificaci&oacute;n, que revise y, en su caso, otorgue nuevamente su consentimiento a los t&eacute;rminos establecidos en el Aviso de Privacidad modificado.</p>
        <h3>X.&nbsp; Legislaci&oacute;n y jurisdicci&oacute;n aplicable.</h3>
        <p><strong>&nbsp;</strong></p>
        <p>Para el caso de resoluci&oacute;n de controversias, o para la interpretaci&oacute;n de cualquiera de los t&eacute;rminos establecidos en el presente Aviso de Privacidad, todas y cada una de las partes involucradas se someter&aacute;n expresamente a la legislaci&oacute;n federal vigente y aplicable en los Estados Unidos Mexicanos, as&iacute; como a la competencia de los Tribunales competentes en la ciudad de Veracruz, Estado de Veracruz de Ignacio de la Llave, M&eacute;xico, renunciando expresamente a la jurisdicci&oacute;n o competencia que pudiera corresponderles en virtud de su domicilio, actual o futuro, o cualquier otra causa generadora de competencia.</p>

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {

  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.max-w-img-190{
  max-width: 190px;
}
</style>
